import Message from 'components/Messages';
import { useProductData } from 'components/Products';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import EmptyMessages from 'components/Common/EmptyMessages';

/**
 * @typedef {{}} MessagesProps
 * @type {React.FC<MessagesProps>}
 */
const Messages = () => {
  const { productUID } = useProductData();
  const { read, unRead } = useSelector(selectors.getProductMessages(productUID));

  const allMessages = [...unRead, ...read];

  return (
    <div id="message-container" className="trend-table--wrapper">
      {allMessages.length < 1 ? <EmptyMessages /> : <Message.List messageUIDs={allMessages} />}
    </div>
  );
};

export default Messages;
