import React from 'react';
import { Menu } from 'antd';
import Badge from './Badge';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useProductData } from 'components/Products';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { PARTNERSHIP_STATUS } from 'constants/partnerships';
import { toCampaignApprovalPath } from 'constants/routes';

const { Item: MenuItem } = Menu;

/**
 *
 * @typedef {{ }} TabsProps
 * @type {React.FC<TabsProps>}
 * @returns
 */
const Tabs = () => {
  const { approvedPartnershipUIDs, productUID } = useProductData();
  const partnerships = useSelector(selectors.getPartnerships());
  const applications = useSelector(selectors.getProductApplications(productUID));
  const { url } = useRouteMatch();
  const location = useLocation();
  let defaultKey = 'summary';
  const arrPathname = location.pathname.split('/');
  const pathIndex = location.pathname.includes('demo') ? 4 : 3;

  defaultKey = [arrPathname[pathIndex] || defaultKey];

  // Update THIS
  const appliedInfluencerCount = applications.length;
  const needsShippingCount = approvedPartnershipUIDs.filter(
    x => partnerships[x].status === PARTNERSHIP_STATUS.NEEDS_SHIPPING
  ).length;
  const { unRead } = useSelector(selectors.getProductMessages(productUID));
  const newMessagesCount = unRead.length;
  const approvalsCount = useSelector(selectors.getNumPendingSubmissions(productUID));

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={defaultKey}
      selectedKeys={defaultKey}
      className="trend-menu-horizontal"
    >
      <MenuItem key="summary">
        <Link to={`${url}/summary`}>Creative Brief</Link>
      </MenuItem>
      <MenuItem key="applications">
        <Link to={`${url}/applications`}>
          {appliedInfluencerCount > 0 && <Badge>{appliedInfluencerCount}</Badge>}
          Applications
        </Link>
      </MenuItem>
      <MenuItem key="creators">
        <Link to={`${url}/creators`}>
          {needsShippingCount > 0 && <Badge>{needsShippingCount}</Badge>}
          Creators &#38; Shipments
        </Link>
      </MenuItem>
      <MenuItem key="messages">
        <Link to={`${url}/messages`}>
          {newMessagesCount > 0 && <Badge>{newMessagesCount}</Badge>}
          Messages
        </Link>
      </MenuItem>
      <MenuItem key="approvals">
        <Link to={toCampaignApprovalPath(productUID)}>
          {approvalsCount > 0 && <Badge>{approvalsCount}</Badge>}
          Approvals
        </Link>
      </MenuItem>
      <MenuItem key="gallery">
        <Link to={`${url}/gallery`}>Content Gallery</Link>
      </MenuItem>
    </Menu>
  );
};

export default Tabs;
