import Form from './Form';
import List from './List';
import ChatItem from './ChatItem';

const Message = {
  List,
  Form,
  ChatItem,
};

export default Message;
