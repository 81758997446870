import { patchBrandUpdateShipment, postBrandCreateShipment } from 'api/shipment';
import {
  favoriteCreator,
  patchUpdateBrand,
  postBrandSendMessage,
  postBrandReadMessage,
  unFavoriteCreator,
  patchBrandViewApproveInvites,
  patchBrandReviewInfluencer,
} from 'api/brand';
import { rejectPartnership } from 'api/partnerships';
import {
  patchBrandArchiveCampaign,
  patchBrandBumpCampaign,
  patchBrandIncrementDownload,
  patchBrandReactivateCampaign,
  patchBrandRelistCampaign,
  patchBrandUnlistCampaign,
} from 'api/campaigns';
import mixpanel from '../../Analytics/Mixpanel';
import { firebaseService } from '../context';

const firebase = firebaseService;

export const brandUnfavoriteInfluencer = async (brandId, payload) => {
  try {
    await unFavoriteCreator(brandId, payload);
    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const brandFavoriteInfluencer = async (brandId, payload) => {
  try {
    await favoriteCreator(brandId, payload);
    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const brandRejectApplication = async (partnershipUID, block) => {
  try {
    await rejectPartnership(partnershipUID, { block });

    if (mixpanel) {
      mixpanel.track('Brand Rejects Partnership', {
        partnershipId: partnershipUID,
      });
    }

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Create Shipment Callable
 *
 * @param {string} partnershipUID
 * @param {{ nonShippable: boolean, carrierCode: string, trackingNumber: string }} params
 */
export const brandCreateShipment = async (partnershipUID, params) => {
  try {
    await postBrandCreateShipment(partnershipUID, params);
    const { carrierCode = null, nonShippable = null } = params;

    if (mixpanel) {
      const shipProductPayload = {};
      if (carrierCode !== null) {
        shipProductPayload.shippingCompany = carrierCode;
      }
      if (nonShippable !== null) {
        shipProductPayload.productNeedsShipping = !nonShippable;
      }
      mixpanel.track('Brand Creates Shipment', {
        ...shipProductPayload,
        partnershipId: partnershipUID,
      });
    }

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Update Shipment Callable
 *
 * @param {string} partnershipUID
 * @param {{ nonShippable: boolean, carrierCode: string, trackingNumber: string, productShipmentUID: string }} params
 */
export const brandUpdateShipment = async (partnershipUID, params) => {
  try {
    await patchBrandUpdateShipment(partnershipUID, params);
    const { carrierCode = null, nonShippable = null } = params;

    if (mixpanel) {
      const shipProductPayload = {};
      if (carrierCode !== null) {
        shipProductPayload.shippingCompany = carrierCode;
      }
      if (nonShippable !== null) {
        shipProductPayload.productNeedsShipping = !nonShippable;
      }
      mixpanel.track('Brand Updates Shipment', {
        ...shipProductPayload,
        partnershipId: partnershipUID,
      });
    }

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Send Message Callable
 *
 * @param {} messagePayload
 *
 * creatorId: string;
 * text: string;
 * type: MessageType;
 * url?: string;
 * thumbnail?: string;
 * messageId?: string;
 */
export const brandSendMessage = async messagePayload => {
  try {
    await postBrandSendMessage(messagePayload);
    if (mixpanel) {
      mixpanel.track('Brand Sends Message');
    }
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Read Message Callable
 *
 * @param {string} messageUID
 * @param {string} text
 */
export const brandReadMessage = async messageUID => {
  try {
    await postBrandReadMessage({ messageThreadId: messageUID });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Bump Product Callable
 *
 * @param {string} productUID
 */
export const brandBumpProduct = async productUID => {
  try {
    await patchBrandBumpCampaign(productUID);

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Unlist Product Callable
 *
 * @param {string} productUID
 */
export const brandUnlistProduct = async productUID => {
  try {
    await patchBrandUnlistCampaign(productUID);

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Relist Product Callable
 *
 * @param {string} productUID
 */
export const brandRelistProduct = async productUID => {
  try {
    await patchBrandRelistCampaign(productUID);

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Archive Product Callable
 *
 * @param {string} productUID
 */
export const brandArchiveProduct = async productUID => {
  try {
    const updatedProduct = await patchBrandArchiveCampaign(productUID);

    return updatedProduct;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Reactivate Product Callable
 *
 * @param {string} productUID
 */
export const brandReactivateProduct = async productUID => {
  try {
    const updatedProduct = await patchBrandReactivateCampaign(productUID);

    return updatedProduct;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Update Account Callable
 *
 * @param {import('types').Brand} params
 */
export const brandUpdateAccount = async params => {
  try {
    await patchUpdateBrand(params);
    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Brand Review Influencer
 *
 * @param {string} reviewId
 * @param {{ attitudeProfessionalism: number, contentQuality: number, comments: string }} params
 * @returns
 */
export const brandReviewInfluencer = async (reviewId, params) => {
  try {
    await patchBrandReviewInfluencer(reviewId, params);

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const brandIncrementProductDownloadCount = async ({
  campaignId,
  creatorId,
  partnershipId,
  count,
  type,
}) => {
  try {
    await patchBrandIncrementDownload(campaignId, {
      creatorId,
      partnershipId,
      count,
      type,
    });
  } catch (e) {
    console.error(e);
  }
};

export const brandViewApproveInvites = async () => {
  try {
    return await patchBrandViewApproveInvites();
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const brandGetShowcase = async () => {
  try {
    const getContentShowcase = firebase.functions.httpsCallable('getContentShowcase');

    const response = await getContentShowcase({ data: {} });
    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};
