import moment from 'moment';
import { Avatar, Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import Influencer from 'components/Influencers';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Badge from 'pages/Campaigns/ShowPage/Badge';

const columns = [
  {
    title: '',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    render: val => <Influencer.Avatar influencerUID={val} />,
    className: 'avatar',
  },
  {
    title: '',
    dataIndex: 'lastMessage',
    key: 'lastMessage',
    className: 'message-col',
    align: 'center',
    render: message => <span className="list-message">{message}</span>,
  },
  {
    title: '',
    dataIndex: 'lastMessageDate',
    key: 'lastMessageDate',
    className: 'time',
    render: val => moment.unix(val).fromNow(),
  },
  {
    title: '',
    dataIndex: 'isUnread',
    key: 'isUnread',
    className: 'count',
    render: hasUnread => hasUnread && <Badge>1</Badge>,
  },
];
/**
 * Message List Component
 *
 * @typedef {{ messageUIDs: string[] }} ListProps
 * @type {React.FC<ListProps>}
 */
const List = ({ messageUIDs }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const messages = useSelector(selectors.getMessages());
  const influencers = useSelector(selectors.getInfluencers());

  const source = messageUIDs.map(messageUID => {
    const message = messages[messageUID];
    const { influencerUID, lastMessage, lastMessageDate, brandUID } = message;
    const { state } = influencers[influencerUID];

    // Handle undefined messages
    const { hasUnread = false } = message.users[brandUID] || {};

    return {
      key: messageUID,
      lastMessageDate,
      lastMessage,
      influencerUID,
      isUnread: hasUnread,
      influencerState: state,
    };
  });

  const rowSelection = record => {
    return {
      onClick: () => history.push(`${url}/${record.key}`),
    };
  };

  return (
    <Table
      columns={columns}
      dataSource={source}
      className="trend-table"
      showHeader={false}
      pagination={false}
      onRow={rowSelection}
    />
  );
};

export default List;
