import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useProduct, useProductData } from 'components/Products';
import './CampaignListingBanner.less';
import { useLocation } from 'react-router-dom';
import { brandBumpProduct } from 'lib/Firebase/callables/brands';
import { notification } from 'antd';
/**
 * Campaign Listing Banner: Campaigns
 * @typedef CampaignListingBannerProps
 * @type {object}
 * @property {boolean} showBanner
 * @property {function} setShowBanner
 */
const EXPIRATION_DAYS = 40;
const EXPIRATION_WARNING_DAYS = 35;
/** @type {React.FC} */
const CampaignListingBanner = ({ showBanner, setShowBanner }) => {
  const [timeUntilExpiration, setTimeUntilExpiration] = useState();
  const { productUID } = useProductData();
  const { createdAt, bumpedAt, isListed } = useProduct(productUID);
  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const tab = pathParts[pathParts.length - 1];

  const listingLastBumpDate = moment.unix(bumpedAt || createdAt);
  const warningStartDate = moment(listingLastBumpDate).add(EXPIRATION_WARNING_DAYS, 'd');
  const expirationDate = moment(listingLastBumpDate).add(EXPIRATION_DAYS, 'd');

  useEffect(() => {
    const timer = setTimeout(() => {
      const daysToExpiration = expirationDate.diff(moment(), 'days');
      const expirationDiff = expirationDate.diff(moment());
      setTimeUntilExpiration(`${daysToExpiration}:${moment(expirationDiff).format('HH:mm:ss')}`);
    }, 1000);

    return () => clearTimeout(timer);
  }, [expirationDate]);

  useEffect(() => {
    setShowBanner(moment().isAfter(warningStartDate));
  }, [productUID, warningStartDate]);

  const titleText = isListed
    ? 'You’ll receive new applications for another: '
    : 'Your project isn’t receiving new creator applications.';

  const subtitleText = isListed
    ? 'Renew your listing for another 40 days.'
    : 'Add your project back to the marketplace to collect fresh creator applications.';

  const ctaText = isListed ? 'Keep Listing Active' : 'Reactivate Listing';

  const handleReactivateListing = () => {
    brandBumpProduct(productUID);
    setShowBanner(false);
    notification.success({
      message: 'Project Active!',
      description: 'You’ll receive applications for an additional 40 days',
    });
  };

  return (
    (showBanner && (
      <aside className={`banner-card ${tab === 'summary' ? 'brief-page' : ''}`}>
        <div className="text-column">
          <h5>
            {titleText}
            {isListed ? timeUntilExpiration : null}
          </h5>
          <p>{subtitleText}</p>
        </div>
        <div className="action-column">
          <button
            onClick={handleReactivateListing}
            type="button"
            className="u-button-reset reactivate-listing-btn"
          >
            {ctaText}
          </button>
        </div>
      </aside>
    ))
  );
};

export default CampaignListingBanner;
