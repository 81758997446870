import Promise from 'bluebird';
import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import { Row, Spin, Col, Button, message, Layout, Input } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import CreatorContent from 'components/CreatorContent';
import CustomEmpty from 'components/Common/CustomEmpty';
import { adminDownloadContent } from 'lib/Download/adminContent';
import { patchMakeUserContentViewer } from '../../../api/admin';
import { firebase } from '../../../lib/Firebase';

const { Content } = Layout;

const LIMIT = 20;
/**
 * Admin v2 Contents Page
 *
 * @type {React.FC}
 */
const ContentsPage = () => {
  const dispatch = useDispatch();
  const [hasFetched, setHasFetched] = useState(false);
  const [contentUIDs, setContentUIDs] = useState([]);
  const influencers = useSelector(selectors.getInfluencers());
  const creatorContents = useSelector(selectors.getCreatorContents());
  const [doc, setDoc] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [contentViewerEmail, setContentViewerEmail] = useState('');
  const [cookies] = useCookies(['isAdmin']);
  const { isAdmin } = cookies;

  const getData = async (newFilters = {}, lastDoc = null) => {
    setHasFetched(false);
    let query = firebase.firestore
      .collection('creatorContents')
      .orderBy('approvedAt', 'desc')
      .limit(LIMIT + 1);

    if (lastDoc) {
      query = query.startAt(lastDoc);
    }
    Object.keys(newFilters).forEach(filter => {
      if (newFilters[filter] === 'all') newFilters[filter] = null;
    });

    if (newFilters) {
      if (newFilters.projectCategory) {
        query = query.where('productCategory', '==', newFilters.projectCategory);
      }
      if (newFilters.brand) {
        query = query.where('brandUID', '==', newFilters.brand);
      }
      if (newFilters.influencer) {
        query = query.where('influencerUID', '==', newFilters.influencer);
      }
      if (newFilters.type) {
        query = query.where('type', '==', newFilters.type);
      }
      if (newFilters.styleCategory) {
        query = query.where('category', '==', newFilters.styleCategory);
      }
    }

    const creatorContentQueryResult = await query.get();
    if (creatorContentQueryResult.empty) {
      message.info('No available Content');
      setDoc(null);
      setContentUIDs([]);
    } else {
      let last = null;

      const newContentUIDs = [];
      const newCreatorContents = {};
      const newInfluencers = {};
      await Promise.each(creatorContentQueryResult.docs, async creatorContentDoc => {
        const data = creatorContentDoc.data();
        last = creatorContentDoc;

        if (!influencers[data.influencerUID]) {
          const influencerDoc = await firebase.firestore
            .collection('influencers')
            .doc(data.influencerUID)
            .get();
          newInfluencers[data.influencerUID] = influencerDoc.data();
        }
        const influencer = influencers[data.influencerUID] || newInfluencers[data.influencerUID];

        if (
          !influencer ||
          (newFilters.creatorLevel &&
            influencer &&
            influencer.payOutTiers.content !== Number(newFilters.creatorLevel))
        ) {
          return;
        }

        newContentUIDs.push(creatorContentDoc.id);
        newCreatorContents[creatorContentDoc.id] = data;
      });

      dispatch(actions.entities.influencers.addInfluencers(newInfluencers));
      dispatch(actions.entities.creatorContents.writeCreatorContents(newCreatorContents));

      setDoc(last);
      setContentUIDs(prevState => {
        if (lastDoc) {
          return [...new Set([...prevState, ...newContentUIDs])];
        }
        return newContentUIDs;
      });
    }

    setHasFetched(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const loadMore = () => {
    getData(filters, doc);
    setPage(p => p + 1);
  };

  function handleChangeFilters(newFilters = {}) {
    getData(newFilters);
    setFilters(newFilters);
    setPage(1);
  }

  async function handleContentDownload(collectionId, contentId) {
    try {
      await adminDownloadContent({ contentId, collectionId }, creatorContents[collectionId]);
    } catch (e) {
      message.error(e.message);
    }
  }

  const handleContentViewerEmailChange = e => {
    setContentViewerEmail(e.target.value);
  };

  const handleAddPermissions = async () => {
    try {
      await patchMakeUserContentViewer(contentViewerEmail);
      message.success(`Successfully added ${contentViewerEmail} as a content viewer.`);
      setContentViewerEmail('');
    } catch (e) {
      message.error(e.message);
    }
  };

  const contentCards = [];
  contentUIDs.slice(0, LIMIT * page).forEach(uid => {
    const { contents } = creatorContents[uid];

    Object.keys(contents).forEach(cUID => {
      contentCards.push(
        <CreatorContent.AdminCard
          key={cUID}
          creatorContentUID={uid}
          contentUID={cUID}
          onDownload={handleContentDownload}
        />
      );
    });
  });

  return (
    <div id="campaign-posts-page" style={{ marginTop: '20px' }}>
      <Row
        type="flex"
        justify="space-around"
        className="post-filter-types"
        style={{ paddingBottom: '20px' }}
      >
        <MinLayout>
          <CreatorContent.AdminFilters onChange={handleChangeFilters} />
          {isAdmin && (
            <div style={{ margin: '10px', maxWidth: 600, display: 'none' }}>
              <Input.Search
                value={contentViewerEmail}
                onChange={handleContentViewerEmailChange}
                placeholder="New Content Viewer Email"
                enterButton="Add Permissions"
                onSearch={handleAddPermissions}
              />
            </div>
          )}
        </MinLayout>
      </Row>

      <Spin spinning={!hasFetched}>
        <Row type="flex" justify="space-around" style={{ marginTop: '10px' }}>
          <MinLayout>
            <div style={{ backgroundColor: '#F5F6F6', marginTop: '10px' }}>
              <Content id="campaign-posts-list" style={{ minHeight: 600 }}>
                <Row type="flex" justify="space-around">
                  <Col span={24}>
                    {contentUIDs.length > 0 ? (
                      <div className="influencer-cards-flex">{contentCards}</div>
                    ) : (
                      <CustomEmpty
                        style={{ marginTop: '10px', textAlign: 'center' }}
                        description="No content yet."
                      />
                    )}
                  </Col>
                </Row>
                <div
                  className="text-center"
                  style={{
                    textAlign: 'center',
                    paddingBottom: '20px',
                    marginTop: '20px',
                    bottom: 0,
                    justifyContent: 'center',
                  }}
                >
                  <Button className="load-more" onClick={loadMore}>
                    Load more
                  </Button>
                </div>
              </Content>
            </div>
          </MinLayout>
        </Row>
      </Spin>
    </div>
  );
};

export default ContentsPage;
